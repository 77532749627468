import { Component } from "react";

export default class Footer extends Component {
  render() {
    return(
      <div className="footer">
        <div className="footerText">
          <a target="blank" href="https://barbaros-dev.xyz" className="name">Barbaros</a> tarafından geliştirildi.
        </div>
        <style>{`
          .footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .footerText {
            font-size: 15px;
            user-select: none;
          }

          .name {
            color: #ff914d;
            cursor: pointer;
            transition: 0.2s;
            text-decoration: none;
            border-radius: 10px;
          }

          .name:hover {
            color: #fff;
            background: #ff914d;
            padding: 6px;
          }
        `}</style>
      </div>
    )
  }
}