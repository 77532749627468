import { Component } from "react";

export default class Toast extends Component {
  render() {
    return(
      <div className="toastOverlay">
        <div className="toast">
          {this.props.text}
        </div>
        <style>{`
          .toast {
            height: 30px;
            background: rgba(0, 0, 0, 0.4);
            position: fixed;
            top: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 20px;
            z-index: 999999;
            transition: 0.2s;
            opacity: 0;
          }

          .toastOverlay {
            height: 100%;
            width: 100%;
            position: fixed;
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
    )
  }
}