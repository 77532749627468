import { Component } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Redirect from "./pages/Redirect";
import Servers from "./pages/Servers";
import ClosePopup from "./pages/ClosePopup";
import Server from "./pages/Server";

export default class App extends Component {
  render() {
    return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={localStorage.getItem("token") ? <Servers/> : <Login/>} />
          <Route path="/login" element={<Redirect/>}/>
          <Route path="/close" element={<ClosePopup/>} />
          <Route path="/server" element={localStorage.getItem("token") ? <Server/> : <Login/>} />
        </Routes>
      </BrowserRouter>
    )
  }
}