import { Component } from "react";
import Splash from "../components/Splash";
import { client } from "../oauth";
import Header from "../components/Header";
import MartiAPI from '../marti-api'
import GuildCard from "../components/GuildCard";

export default class Servers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      guilds: [],
      botGuilds: []
    }
  }

  componentDidMount() {
    client.getGuilds(localStorage.getItem("token")).then(async data => {
      const guilds = await MartiAPI.guilds()
      this.setState({ guilds: data.filter(guild => (guild.permissions & 0x0000000000000008) === 0x0000000000000008), show: false, botGuilds: guilds })
    })
  }

  render() {
    return(
      <div>
        <Splash show={this.state.show} />
        <Header title="Sunucularım" />
        <div className="container">
          {this.state.guilds.map((guild, i) => (
            <GuildCard botGuilds={this.state.botGuilds} guild={guild} key={i} />
          ))}
        </div>
        <style>{`
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        `}</style>
      </div>
    )
  }
}