import { Component } from "react";
import MartiLogo from '../Martı BOT.png'
import { FaDiscord } from 'react-icons/fa'
import Footer from "../components/Footer";
import Splash from "../components/Splash";

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
  }

  componentDidMount() {
    if(localStorage.getItem("token")) window.location.pathname = "/servers"
    else this.setState({ show: false })
  }

  render() {
    const styles = {
      container: {
        width: "100%",
        height: "100%",
        position: "fixed",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      },
      header: {
        fontSize: 55
      },
      image: {
        width: 100,
        position: "absolute",
        top: -40,
        right: -35
      }
    }

    return(
      <div style={styles.container}>
        <Splash show={this.state.show} />
        <div style={{ position: "relative" }}>
          <h1 style={styles.header}>Martı BOT</h1>
          <img alt="logo" src={MartiLogo} style={styles.image}></img>
        </div>
        <button onClick={() => {
          window.open(
            "https://discord.com/api/oauth2/authorize?client_id=1041687747498356766&redirect_uri=https%3A%2F%2Fmarti.barbaros-dev.xyz%2Flogin&response_type=code&scope=identify%20guilds%20guilds.members.read",
            '',
            'toolbar=0,scrollbars=2,location=0,statusbar=1,menubar=0,resizable=0,width=500,height=775,left=200,top=100'          
          )
        }}><FaDiscord style={{ paddingRight: 7 }} size={25} /> Giriş Yap</button>
        <Footer/>
        <style>{`
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Montserrat";
            font-size: 20px;
            padding: 10px 15px 10px 15px;
            color: #fff;
            border: 3px solid transparent;
            background: #031628;
            border-radius: 100px;
            outline: none;
            cursor: pointer;
            transition: 0.2s;
          }

          button:hover {
            border-color: #021220;
          }
        `}</style>
      </div>
    )
  }
}