import { Component } from "react";
import { client } from "../oauth";

export default class Redirect extends Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get("code")
    client.getAccessToken(code).then(data => {
      localStorage.setItem("token", data.accessToken)
      window.opener.location = window.opener.location
      window.close()
    })
  }

  render() {
    return(
      <div>
        
      </div>
    )
  }
}