import CryptoJS from "crypto-js"

export default function GuildCard({ guild, botGuilds }) {
  function getInitials(name) {
    var parts = name.split(' ')
    var initials = ''

    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }

    return initials
  }

  function encode(id) {
    const myString = id;
    const encodedWord = CryptoJS.enc.Utf8.parse(myString);
    const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
    return encoded;
  }

  return(
    <div className="card">
      {guild.icon ? 
        <img className="serverIcon" alt="serverIcon" src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp`}></img>
      :
        <div className="serverIcon"><label className="iconText">{getInitials(guild.name)}</label></div>
      }
      <span className="name">{guild.name}</span>
      <button onClick={() => {
        if(botGuilds.includes(guild.id)) {
          window.location = `/server?i=${encode(guild.id)}`
        } else {
          window.open(
            `https://discord.com/api/oauth2/authorize?scope=bot+applications.commands&response_type=code&redirect_uri=https%3A%2F%2Fmarti.barbaros-dev.xyz%2Fclose&permissions=2147822672&client_id=1041687747498356766&guild_id=${guild.id}`,
            '',
            'toolbar=0,scrollbars=2,location=0,statusbar=1,menubar=0,resizable=0,width=500,height=775,left = 200,top = 100')
          localStorage.setItem("lastServer", guild.id)
        }
      }} className="button">{botGuilds.includes(guild.id) ? "Ayarla" : "Ekle"}</button>
      <style>{`
        .card {
          width: 500px;
          background: rgba(0, 0, 0, 0.4);
          padding: 20px;
          border-radius: 20px;
          margin: 10px;
          display: flex;
          align-items: center;
          position: relative;
        }
        
        .serverIcon {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          background: rgba(255, 255, 255, 0.1);
        }

        .iconText {
          max-width: 50px;
          overflow: hidden;
          white-space: nowrap;
        }

        .name {
          margin-left: 10px;
          max-width: 350px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .button {
          position: absolute;
          right: 20px;
          border: 2px solid #010a12;
          background: #010a12;
          font-family: Montserrat;
          color: #fff;
          width: 70px;
          height: 40px;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.2s;
          outline: none;
        }

        .button:hover {
          border-color: rgba(255, 255, 255, 0.7);
        }
      `}</style>
    </div>
  )
}