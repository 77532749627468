import CryptoJS from 'crypto-js';

const serverAdress = 'https://marti-api.glitch.me';

//const checkStatus = (response) => {
//  
//  if (response.status !== 200) {
//    if (!debug) showError('MartiAPI did not return a 200 response.');
//    else debugLog('MartiAPI returned: ' + response.status + ' | ' + response.statusText);
//  }
//};

const showError = (text) => {
  throw Error(text);
};

//const debugLog = (text) => {
//  console.log(
//    '%c[' + '%cMARTIAPI_DEBUG%c' + '] %c' + text, 'color: gray', 'color: cyan', 'color: gray', 'clear'
//  );
//};

export default class MartiAPI {

  static initalize ({ accessKey }) {
    if (!accessKey) showError('API not initalized. Please read documentation.');

    return { message: "Successfully Initalized" };
  };

  static encode(id) {
    const myString = id; // Utf8-encoded string

    const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
    const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
    return encoded;
  }

  
  static decode(id) {
    const encoded = id;
    const encodedWord = CryptoJS.enc.Base64.parse(encoded);
    const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
    return decoded;
  }
  
  static async getGameChannel ({ id }) {
    const response = await fetch(serverAdress + '/v3/gameChannel', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id) } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  };

  static async setGameChannel ({ id, data }) {
    const response = await fetch(serverAdress + '/v3/gameChannel', { method: "POST", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id), "Data": data } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  };

  static async getLanguage ({ id }) {
    const response = await fetch(serverAdress + '/v3/language', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id) } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  };

  static async setLanguage ({ id, data }) {
    const response = await fetch(serverAdress + '/v3/language', { method: "POST", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id), "Data": data } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  };

  static async getIsGameStarted ({ id }) {
    const response = await fetch(serverAdress + '/v3/isGameStarted', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id) } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  };

  static async createGame ({ id }) {
    const response = await fetch(serverAdress + '/v3/createGame', { method: "POST", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id) } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }

  static async deleteGame ({ id }) {
    const response = await fetch(serverAdress + '/v3/deleteGame', { method: "DELETE", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": this.encode(id) } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }

  static async guilds () {
    const response = await fetch(serverAdress + '/v3/guilds', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB" } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }

  static async allGuilds () {
    const response = await fetch(serverAdress + '/v3/allGuilds', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB" } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }

  static async guild ({ id }) {
    const response = await fetch(serverAdress + '/v3/guild', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": id } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }

  static async channels ({ id }) {
    const response = await fetch(serverAdress + '/v3/channels', { method: "GET", headers: { "Authorization": "s7JXzIEL01iuWihWbDFB", "Id": id } });
  
    const body = await response.json();
    if(body.status === "error") {
      return "Error: " + body.message
    }else{
      return body.data;
    }
  }
}