import { Component } from "react";
import Splash from "../components/Splash";
import CryptoJS from "crypto-js";

export default class ClosePopup extends Component {
  componentDidMount() {
    function encode(id) {
      const myString = id;
      const encodedWord = CryptoJS.enc.Utf8.parse(myString);
      const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
      return encoded;
    }
    const serverID = encode(localStorage.getItem("lastServer"))
    window.opener.location = `/server?i=${serverID}`
    localStorage.removeItem("lastServer")
    window.close()
  }

  render() {
    return(
      <div>
        <Splash />
      </div>
    )  
  }
}