import { Component } from "react";
import logo from '../Martı BOT.png'
import { client } from "../oauth";

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      avatarURL: ""
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    client.getUser(localStorage.getItem("token")).then(data => {
      this.setState({ avatarURL: data.avatarURL() })
    })
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const dropdown = document.getElementById("dropdown");
    const dropdownContainer = document.getElementsByClassName("dropdownContainer")[0]
    console.log(dropdownContainer.contains(event.target))
    if (dropdownContainer && !dropdownContainer.contains(event.target)) {
      const dropdownClassList = dropdown.classList;
      if (dropdownClassList.contains("dropdownFadeIn")) {
        dropdownClassList.remove("dropdownFadeIn");
        dropdownClassList.add("dropdownFadeOut");
      }
    }
  }

  render() {
    return(
      <div className="header">
        <a href="/" className="title">
          Martı BOT
          <img className="headerLogo" alt="logo" src={logo} width={60}></img>
        </a>
        {this.props.title ? <span className="pageTitle">{this.props.title}</span> : null}
        <div className="dropdownContainer">
          <img onClick={() => {
            const dropdown = document.getElementById("dropdown").classList
            if(dropdown.contains("dropdownFadeIn")) {
              dropdown.remove("dropdownFadeIn")
              dropdown.add("dropdownFadeOut")
            } else {
              dropdown.remove("dropdownFadeOut")
              dropdown.add("dropdownFadeIn")
            }
          }} className="avatar" alt="avatar" src={this.state.avatarURL} width={50} height={50}></img>
          <div onClick={() => {
            localStorage.removeItem("token")
            window.location = `${window.location.origin}/`
          }} id="dropdown" className="dropdown">
            <label className="dropdownItem">Çıkış Yap</label>
          </div>
        </div>
        <style>{`
          .header {
            position: relative;
            top: 0;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            background: rgba(0, 0, 0, 0.2);
            user-select: none;
          }

          .title {
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 5%;
          }

          .headerLogo {
            margin-left: 20px;
          }

          .pageTitle {
            font-size: 30px;
          }

          .dropdownContainer {
            position: absolute;
            right: 5%;
          }

          .dropdown {
            background: #021220;
            padding: 10px;
            position: absolute;
            right: -25px;
            top: 65px;
            border-radius: 10px;
            width: 80px;
            display: none;
            justify-content: center;
            align-items: center;
            transition: 0.2s;
            cursor: pointer;
          }

          .dropdown::before {
            content: "";
            position: absolute;
            top: -18px;
            right: 50%;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent #021220 transparent;
            transform: translateX(50%);
            z-index: 1;
          }

          .dropdownFadeIn {
            animation-name: fadeIn;
            animation-duration: 0.2s;
            animation-iteration-count: 1;
            display: flex;
          }

          .dropdownFadeOut {
            animation-name: fadeOut;
            animation-duration: 0.2s;
            animation-iteration-count: 1;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes fadeOut {
            from { opacity: 1; display: flex; }
            to { opacity: 0; display: none; }
          }

          .dropdownItem {
            cursor: pointer;
          }

          .avatar {
            border-radius: 100%;
            display: flex;
            background: #021220;
            cursor: pointer;
            border: 2px solid #021220;
            transition: 0.2s;
          }

          .avatar:hover {
            border-color: rgba(255, 255, 255, 0.1);
          }
        `}</style>
      </div>
    )
  }
}