import { Component } from "react";
import Splash from "../components/Splash";
import Header from "../components/Header";
import MartiAPI from "../marti-api";
import Toast from "../components/Toast";
import { client } from "../oauth";

export default class Server extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSplash: true,
      guild: "",
      channels: [],
      isGameStarted: "",
      gameChannel: "",
      language: "",
      toastText: ""
    }
    this.checkInfo = this.checkInfo.bind(this)
  }

  async checkInfo() {
    const searchParams = new URLSearchParams(window.location.search)
    const guildID = MartiAPI.decode(searchParams.get("i"))
    const userGuilds = await client.getGuilds(localStorage.getItem("token"))
    if(!userGuilds.find(guild => guild.id === guildID)) return setTimeout(() => window.location = `${window.location.origin}/servers`, 1000)
    const guild = await MartiAPI.guild({ id: guildID })
    const channels = await MartiAPI.channels({ id: guildID })
    const isGameStarted = await MartiAPI.getIsGameStarted({ id: guildID })
    const gameChannel = await MartiAPI.getGameChannel({ id: guildID })
    const language = await MartiAPI.getLanguage({ id: guildID })
    this.setState({
      guild: guild,
      channels: Object.values(channels).filter(channel => channel.type === 0),
      isGameStarted: isGameStarted === "There is no data." ? false : isGameStarted,
      gameChannel: gameChannel,
      language: language === "There is no data." ? "tr" : language,
      showSplash: false }, () => {
      document.querySelector("select").style.width = `${document.getElementById(document.querySelector("select").value).text.length + 2}ch`
    })
  }

  componentDidMount() {
    this.checkInfo()
  }

  render() {
    return(
      <div>
        <Toast text={this.state.toastText} />
        <div className="container">
          <div className="card">
            <h2 className="cardTitle">{this.state.guild.name}</h2>
            <span className="gameState">{`Oyun Durumu: ${this.state.isGameStarted ? "Başlamış" : "Başlamamış"}`}</span>
            <div className="gameChannel">
              <span>Oyun Kanalı: </span>
              <select onChange={() => {
                document.querySelector("select").style.width = `${document.getElementById(document.querySelector("select").value).text.length + 2}ch`
                MartiAPI.setGameChannel({ id: this.state.guild.id, data: document.querySelector("select").value }).then(() => {
                  this.setState({ toastText: "Sunucunuz için oyun kanalı değiştirildi" }, () => {
                    document.getElementsByClassName("toast")[0].style.opacity = 1
                    setTimeout(() => {
                      document.getElementsByClassName("toast")[0].style.opacity = 0
                    }, 1500);
                  })
                })
              }} className="channels">
                {this.state.channels.map((channel, i) => (
                  <option id={channel.id} key={i} value={channel.id}  selected={channel.id === this.state.gameChannel}># {channel.name}</option>
                ))}
              </select>
            </div>
            <div className="languageContainer">
              <span>Oyun Dili: </span>
              <input onChange={() => {
                this.setState({ language: this.state.language === "tr" ? "en" : "tr" }, () => {
                  MartiAPI.setLanguage({ id: this.state.guild.id, data: this.state.language }).then(() => {
                    this.setState({ toastText: "Sunucunuz için oyun dili değiştirildi" }, () => {
                      document.getElementsByClassName("toast")[0].style.opacity = 1
                      setTimeout(() => {
                        document.getElementsByClassName("toast")[0].style.opacity = 0
                      }, 1500);
                    })
                  })
                })
              }} className="checkbox" id="checkbox" type="checkbox" />
              <label className="language" for="checkbox">{this.state.language === "tr" ? "Türkçe" : "English"}</label>
            </div>
            <button onClick={() => {
              this.setState({ showSplash: true })
              if(this.state.isGameStarted) return MartiAPI.deleteGame({ id: this.state.guild.id }).then(() => {
                this.checkInfo()
              })
              MartiAPI.setGameChannel({ id: this.state.guild.id, data: document.querySelector("select").value }).then(() => {
                MartiAPI.setLanguage({ id: this.state.guild.id, data: this.state.language }).then(() => {
                  MartiAPI.createGame({ id: this.state.guild.id }).then(() => {
                    this.checkInfo()
                  })
                })
              })
            }} className="button">{this.state.isGameStarted ? "Oyunu Sonlandır" : "Oyunu Başlat"}</button>
          </div>
        </div>
        <Splash show={this.state.showSplash} />
        <Header/>
        <style>{`
          .container {
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card {
            background: rgba(0, 0, 0, 0.2);
            width: 470px;
            height: 500px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding-top: 10px;
            flex-direction: column;
            position: relative;
          }

          .cardTitle {
            max-width: 400px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .gameState, .gameChannel, .languageContainer {
            margin: 10px;
          }

          .channels {
            background: #031628;
            color: #fff;
            border: 3px solid #031628;
            outline: none;
            font-family: Montserrat;
            padding: 5px;
            border-radius: 20px;
            transition: 0.2s;
            cursor: pointer;
            min-width: 100px;
          }

          .channels:hover {
            border-color: rgba(255, 255, 255, 0.7);
          }

          .checkbox {
            display: none;
          }

          .language {
            cursor: pointer;
            user-select: none;
          }

          .button {
            position: absolute;
            bottom: 60px;
            border: 3px solid #020d18;
            padding: 7px;
            border-radius: 20px;
            background: #020d18;
            color: #fff;
            font-family: Montserrat;
            cursor: pointer;
            transition: 0.2s;
          }

          .button:hover {
            border-color: rgba(255, 255, 255, 0.5);
          }
        `}</style>
      </div>
    )
  }
}